"use client";
import { SignedIn, UserButton } from "@clerk/nextjs";

export default function Header() {
  return (
    <SignedIn>
      <div className="mx-4">
        <UserButton />
      </div>
    </SignedIn>
  );
}
